<template>
  <div class="search">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <van-sticky>
        <search-top @changeOrder="changeOrder" :order="order"></search-top>
      </van-sticky>
      <lists :productList="productList" ref="search"></lists>
    </van-list>
  </div>
</template>
<script>
import Lists from "@main/common/Lists";
import SearchTop from "../search/components/SearchTop";

import Vue from "vue";
import { Sticky, List } from "vant";

Vue.use(List).use(Sticky);
export default {
  name: "SearchClass",
  components: {
    Lists,
    SearchTop,
  },
  data() {
    return {
      productList: [],
      className: "", // 搜索关键词
      classId: null, // 分类id或分类id列表
      pageIndex: 1,
      order: "", // 排序
      loading: true,
      finished: false,
    };
  },
  created() {
    const { ClassName, Order, ClassId } = this.$route.query;
    this.className = ClassName;
    this.order = Order;
    this.getSearchProductByPage();
    // console.log(this.ClassName)
    this.className && (document.title = this.className);
    ClassId && (this.classId = ClassId); // 兼容全部分类
  },
  mounted() {},
  methods: {
    // 获取搜索页数据
    getSearchProductByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      console.log(this.classId);
      console.log(545454);
      if (this.order !== 1 && this.order !== 2) {
        param = {
          ClassName: this.className,
          PageIndex: this.pageIndex,
        };
        const { ClassId } = this.$route.query;
        if (ClassId) {
          param = {
            ScreenValues: ClassId,
            PageIndex: this.pageIndex,
          };
        }
      } else {
        param = {
          ClassName: this.className,
          Order: this.order,
          PageIndex: this.pageIndex,
        };
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            PageIndex: this.pageIndex,
          };
        }
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          const data = res.data;
          this.productList = data;
          // this.isfirst = false;
          if (
            this.productList === null ||
            this.productList === "" ||
            this.productList.length === 0
          ) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          // console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 筛选
    changeOrder(type) {
      this.$refs.search.scrollTop = 0;
      this.order = type;
      this.pageIndex = 1;
      this.loading = true;
      this.finished = false;
      this.getSearchProductByPage();
    },
    // 加载更多
    onLoad() {
      let param = {};
      this.finished = false;
      if (this.order !== 1 && this.order !== 2) {
        param = {
          ClassName: this.className,
          PageIndex: this.pageIndex + 1,
        };
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            PageIndex: this.pageIndex + 1,
          };
        }
      } else {
        param = {
          ClassName: this.className,
          Order: this.order,
          PageIndex: this.pageIndex + 1,
        };
        if (this.classId) {
          param = {
            ScreenValues: this.classId,
            Order: this.order,
            PageIndex: this.pageIndex + 1,
          };
        }
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.finished = false;
            this.productList = this.productList.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  width: 100%;
  background-color: #f6f6f6;
}
</style>
